<template>
    <main class="h-100" v-if="selectedMatch">
      <div class="container">
        <div>
          <h3>{{Object.keys(selectedMatch.athletes).join(' vs ')}}</h3>
        </div>
        TODO
          <div class="mt-3">
            <h3>Coming Soon:</h3>
            <ul>
              <li>General data about a match</li>
              <li>A list of viewpoints connected with this match</li>
              <li>Task results related to this match</li>
              <li>Easy actions to manage a match stats / share results</li>
              <li>???? (Input welcome)</li>
            </ul>
          </div>
      </div>
    </main>
    <main class="h-100 w-100" v-else>
      <div v-if="errorLoading" class="h-100 w-100 d-flex justify-content-center">
        <div class="text-center my-auto"> Unable to load match: {{ errorLoading }}</div>
      </div>
      <div v-else class="container">
        Loading
      </div>
    </main>
</template>
<script>

import { mapGetters } from 'vuex';
export default {
  mixins: {  },
  components: {
  },
    data() {
        return {
          match: null,
          errorLoading: null,
        }
    },
  async mounted() {
    console.log("Match mounted");
    try { 
      console.log(`Requesting match ID: ${this.$route.params.id}`)
      await this.$store.dispatch('getMatch', this.$route.params.id);
      this.$store.dispatch('selectMatch', this.$route.params.id);
    } catch (e) {
      this.errorLoading = e.message;
    }
  },
  async destroyed() {
    console.log("Match destroyed");
  },
  computed: {
      ...mapGetters(['selectedMatch']),
  },
  watch: {
  },
  methods: {
  }
}
</script>

<style>

</style>